var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableColumns,
          "data-source": _vm.apiResData.records,
          pagination: _vm.pagination,
          loading: _vm.showLoading,
          "row-selection": _vm.rowSelection,
          rowKey: _vm.rowKey,
          scroll: { x: _vm.scrollX }
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.columnsCustomSlots, function(colCustom) {
              return {
                key: colCustom.customRender,
                fn: function(record) {
                  return [
                    _vm._t(colCustom.customRender, null, { record: record })
                  ]
                }
              }
            })
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }